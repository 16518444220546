import Session from '@/Session';
import sundata from '@/company-configurations/Sundata';
import saman from '@/company-configurations/Saman';
import funky from '@/company-configurations/Funky';

export default {
  methods: {
    load() {
      const companyConfigMap = {
        sundata: sundata,
        saman: saman,
        sirius: sundata,
        funky: funky,
      };

      const company = this.getCompanyNameFromHost();
      let companyConfig = companyConfigMap[company];

      if (!companyConfig) {
        companyConfig = companyConfigMap['sundata'];
      }

      Session.companyConfig = companyConfig;

      Object.entries(companyConfig.theme).forEach(([key, color]) => {
        document.documentElement.style.setProperty(key, color);
      });
    },

    getCompanyNameFromHost() {
      const host = window.location.host;
      return host.split('.')[0];
    },

    getCapitalizedCompanyName() {
      const companyName = this.getCompanyNameFromHost();
      return companyName[0].toUpperCase() + companyName.substring(1);
    },

    getCompanyLogo() {
      try {
        return require('@/assets/' +
          this.getCompanyNameFromHost() +
          '-logo.svg');
      } catch {
        return require('@/assets/sundata-logo.svg');
      }
    },

    getSundataLogo(deviceType) {
      return require('@/assets/footer-logos/' +
        Session.companyConfig.company.footerLogo +
        '-' +
        deviceType +
        '.svg');
    },

    getFavIcon() {
      try {
        return require('@/assets/' +
          this.getCompanyNameFromHost() +
          '-favicon.png');
      } catch (error) {
        return require('@/assets/sundata-favicon.png');
      }
    },
  },
};
